import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="spells-fabricate-to-gust-of-wind"></a><h2>Spells</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">Fabricate - Gust of Wind</span>
    </h2>
    <h6><a id="fabricate">Fabricate</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: See text</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: Up to 10 cu.
ft./level;
see text</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span>
    <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You convert material of one sort into a product that is of the
same material. Creatures or magic items cannot be created or transmuted
by the fabricate spell. The quality of items made by this spell is
commensurate with the quality of material used as the basis for the new
fabrication. If you work with a mineral, the target is reduced to 1
cubic foot per level instead of 10 cubic feet.</p>
    <p>You must make an appropriate <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a> check to fabricate
articles
requiring a high degree of craftsmanship.</p>
    <p>Casting requires 1 round per 10 cubic feet (or 1 cubic foot)
of material to be affected by the spell.</p>
    <p><i>Material Component</i>: The original material, which costs
the same amount as the raw materials required to craft the item to be
created.</p>
    <h6><a id="faerie-fire">Faerie Fire</a></h6>
    <p className="initial"><i>Evocation [Light]</i></p>
    <span className="stat-block"><b>Level</b>: Drd 1</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Area</b>: Creatures and
objects
within a 5-ft.-radius burst</span> <span className="stat-block"><b>Duration</b>:
1 min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>A pale glow surrounds and outlines the subjects. Outlined
subjects shed light as candles. Outlined creatures do not benefit from
the concealment normally provided by darkness (though a 2nd-level or
higher magical darkness effect functions normally), blur, displacement,
invisibility, or similar effects. The light is too dim to have any
special effect on undead or dark-dwelling creatures vulnerable to
light. The faerie fire can be blue, green, or violet, according to your
choice at the time of casting. The faerie fire does not cause any harm
to the objects or creatures thus outlined.</p>
    <h6><a id="false-life">False Life</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1 hour/level or until
discharged; see text</span>
    <p>You harness the power of unlife to grant yourself a limited
ability to avoid death. While this spell is in effect, you gain
temporary hit points equal to 1d10 +1 per caster level (maximum +10).</p>
    <p><i>Material Component</i>: A small amount of alcohol or
distilled spirits, which you use to trace certain sigils on your body
during casting. These sigils cannot be seen once the alcohol or spirits
evaporate.</p>
    <h6><a id="false-vision">False Vision</a></h6>
    <p className="initial"><i>Illusion (Glamer)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 5, Sor/Wiz 5, Trickery
5</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Area</b>:
40-ft.-radius emanation</span> <span className="stat-block"><b>Duration</b>:
1 hour/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>Any divination (scrying) spell used to view anything within
the area of this spell instead receives a false image (as the major
image spell), as defined by you at the time of casting. As long as the
duration lasts, you can concentrate to change the image as desired.
While you aren&rsquo;t concentrating, the image remains static.</p>
    <p><i>Arcane Material Component</i>: The ground dust of a piece
of jade worth at least 250 gp, which is sprinkled into the air when the
spell is cast.</p>
    <h6><a id="fear">Fear</a></h6>
    <p className="initial"><i>Necromancy [Fear, Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 3, Sor/Wiz 4</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 30 ft.</span> <span className="stat-block"><b>Area</b>: Cone-shaped burst</span> <span className="stat-block"><b>Duration</b>: 1 round/level or 1
round; see text</span> <span className="stat-block"><b>Saving Throw</b>:
Will partial</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>An <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> cone of terror causes
each living creature in the
area to become <a href="abilitiesAndConditions.html#panicked" style={{
        "color": "rgb(87, 158, 182)"
      }}>panicked</a> unless it succeeds on a
Will save. If cornered,
a panicked creature begins <a href="abilitiesAndConditions.html#cowering" style={{
        "color": "rgb(87, 158, 182)"
      }}>cowering</a>. If the Will save
succeeds, the
creature is <a href="abilitiesAndConditions.html#shaken" style={{
        "color": "rgb(87, 158, 182)"
      }}>shaken</a> for 1 round.</p>
    <p><i>Material Component</i>: Either the heart of a hen or a
white feather.</p>
    <h6><a id="feather-fall">Feather Fall</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1, Sor/Wiz 1</span> <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 1 free action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: One Medium or
smaller
freefalling object or creature/level, no two of which may be more than
20 ft. apart</span> <span className="stat-block"><b>Duration</b>: Until
landing or 1
round/level</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates
(harmless) or Will negates (object)</span> <span className="stat-block"><b>Spell
Resistance</b>: Yes (object)</span>
    <p>The affected creatures or objects fall slowly. Feather fall
instantly changes the rate at which the targets fall to a mere 60 feet
per round (equivalent to the end of a fall from a few feet), and the
subjects take no damage upon landing while the spell is in effect.
However, when the spell duration expires, a normal rate of falling
resumes.</p>
    <p><i>The spell affects one or more Medium or smaller creatures
(including gear and carried objects up to each creature&rsquo;s maximum
load)
or objects, or the equivalent in larger creatures</i>: A Large creature
or object counts as two Medium creatures or objects, a Huge creature or
object counts as two Large creatures or objects, and so forth.</p>
    <p>You can cast this spell with an instant utterance, quickly
enough to save yourself if you unexpectedly fall. Casting the spell is
a free action, like casting a quickened spell, and it counts toward the
normal limit of one quickened spell per round. You may even cast this
spell when it isn&rsquo;t your turn.</p>
    <p>This spell has no special effect on ranged weapons unless they
are falling quite a distance. If the spell is cast on a falling item
the object does half normal damage based on its weight, with no bonus
for the height of the drop.</p>
    <p>Feather fall works only upon free-falling objects. It does not
affect a sword blow or a charging or flying creature.</p>
    <h6><a id="feeblemind">Feeblemind</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Target</b>: One creature</span>
    <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: Will negates; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>If the target creature fails a Will saving throw, its
Intelligence and Charisma scores each drop to 1. The affected creature
is unable to use Intelligence- or Charisma-based skills, cast spells,
understand language, or communicate coherently. Still, it knows who its
friends are and can follow them and even protect them. The subject
remains in this state until a heal, limited wish, miracle, or wish
spell is used to cancel the effect of the feeblemind. A creature that
can cast arcane spells, such as a sorcerer or a wizard, takes a
&ndash;4
penalty on its saving throw.</p>
    <p><i>Material Component</i>: A handful of clay, crystal, glass,
or mineral spheres.</p>
    <h6><a id="find-the-path">Find the Path</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Brd 6, Clr 6, Drd 6,
Knowledge 6, Travel 6</span> <span className="stat-block"><b>Components</b>:
V, S, F</span> <span className="stat-block"><b>Casting Time</b>: 3 rounds</span>
    <span className="stat-block"><b>Range</b>: Personal or touch</span> <span className="stat-block"><b>Target</b>: You or creature touched</span> <span className="stat-block"><b>Duration</b>: 10 min./level</span> <span className="stat-block"><b>Saving Throw</b>: None or Will
negates (harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
No or Yes
(harmless)</span>
    <p>The recipient of this spell can find the shortest, most direct
physical route to a specified destination, be it the way into or out of
a locale. The locale can be outdoors, underground, or even inside a
maze spell. Find the path works with respect to locations, not objects
or creatures at a locale. The location must be on the same plane as you
are at the time of casting.</p>
    <p>The spell enables the subject to sense the correct direction
that will eventually lead it to its destination, indicating at
appropriate times the exact path to follow or physical actions to take.
For example, the spell enables the subject to sense trip wires or the
proper word to bypass a glyph of warding. The spell ends when the
destination is reached or the duration expires, whichever comes first.
Find the path can be used to remove the subject and its companions from
the effect of a maze spell in a single round.</p>
    <p>This divination is keyed to the recipient, not its companions,
and its effect does not predict or allow for the actions of creatures
(including guardians).</p>
    <p><i>Focus</i>: A set of divination counters of the sort you
favor.</p>
    <h6><a id="find-traps">Find Traps</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Clr 2</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1 min./level</span>
    <p>You gain intuitive insight into the workings of traps. You can
use the <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a> skill to detect traps
just as a rogue can. In addition,
you gain an insight bonus equal to one-half your caster level (maximum
+10) on Search checks made to find traps while the spell is in effect.</p>
    <p>Note that find traps grants no ability to disable the traps
that you may find.</p>
    <h6><a id="finger-of-death">Finger of Death</a></h6>
    <p className="initial"><i>Necromancy [Death]</i></p>
    <span className="stat-block"><b>Level</b>: Drd 8, Sor/Wiz 7</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One living
creature</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span>
    <span className="stat-block"><b>Saving Throw</b>: Fortitude partial</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You can slay any one living creature within range. The target
is entitled to a Fortitude saving throw to survive the attack. If the
save is successful, the creature instead takes 3d6 points of damage +1
point per caster level (maximum +25).</p>
    <p>The subject might die from damage even if it succeeds on its
saving throw.</p>
    <h6><a id="fire-seeds">Fire Seeds</a></h6>
    <p className="initial"><i>Conjuration (Creation) [Fire]</i></p>
    <span className="stat-block"><b>Level</b>: Drd 6, Fire 6, Sun 6</span>
    <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Targets</b>:
Up to four touched
acorns or up to eight touched holly berries</span> <span className="stat-block"><b>Duration</b>: 10 min./level or until
used</span> <span className="stat-block"><b>Saving Throw</b>: None or
Reflex
half; see text</span> <span className="stat-block"><b>Spell Resistance</b>:
No</span>
    <p>Depending on the version of fire seeds you choose, you turn
acorns into <a href="specialAttacks.html#throw-splash-weapon" style={{
        "color": "rgb(87, 158, 182)"
      }}>splash weapons</a> that you or
another character can throw, or
you turn holly berries into bombs that you can detonate on command.</p>
    <p><i>Acorn Grenades</i>: As many as four acorns turn into
special splash weapons that can be hurled as far as 100 feet. A ranged
touch attack roll is required to strike the intended target. Together,
the acorns are capable of dealing 1d6 points of fire damage per caster
level (maximum 20d6), divided up among the acorns as you wish.</p>
    <p>Each acorn explodes upon striking any hard surface. In
addition to its regular fire damage, it deals 1 point of splash damage
per die, and it ignites any combustible materials within 10 feet. A
creature within this area that makes a successful Reflex saving throw
takes only half damage; a creature struck directly is not allowed a
saving throw.</p>
    <p><i>Holly Berry Bombs</i>: You turn as many as eight holly
berries into special bombs. The holly berries are usually placed by
hand, since they are too light to make effective thrown weapons (they
can be tossed only 5 feet). If you are within 200 feet and speak a word
of command, each berry instantly bursts into flame, causing 1d8 points
of fire damage +1 point per caster level to every creature in a 5-foot
radius burst and igniting any combustible materials within 5 feet. A
creature in the area that makes a successful Reflex saving throw takes
only half damage.</p>
    <p><i>Material Component</i>: The acorns or holly berries.</p>
    <h6><a id="fire-shield">Fire Shield</a></h6>
    <p className="initial"><i>Evocation [Fire or Cold]</i></p>
    <span className="stat-block"><b>Level</b>: Fire 5, Sor/Wiz 4, Sun 4</span>
    <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1 round/level (D)</span>
    <p>This spell wreathes you in flame and causes damage to each
creature that attacks you in melee. The flames also protect you from
either cold-based or fire-based attacks (your choice).</p>
    <p>Any creature striking you with its body or a handheld weapon
deals normal damage, but at the same time the attacker takes 1d6 points
of damage +1 point per caster level (maximum +15). This damage is
either cold damage (if the shield protects against fire-based attacks)
or fire damage (if the shield protects against cold-based attacks). If
the attacker has spell resistance, it applies to this effect. Creatures
wielding weapons with exceptional reach are not subject to this damage
if they attack you.</p>
    <p>When casting this spell, you appear to immolate yourself, but
the flames are thin and wispy, giving off light equal to only half the
illumination of a normal torch (10 feet). The color of the flames is
determined randomly (50% chance of either color)&mdash;blue or green if
the
chill shield is cast, violet or blue if the warm shield is employed.
The special powers of each version are as follows.</p>
    <p><i>Warm Shield</i>: The flames are warm to the touch. You take
only half damage from cold-based attacks. If such an attack allows a
Reflex save for half damage, you take no damage on a successful save.</p>
    <p><i>Chill Shield</i>: The flames are cool to the touch. You
take only half damage from fire-based attacks. If such an attack allows
a Reflex save for half damage, you take no damage on a successful save.</p>
    <p><i>Arcane Material Component</i>: A bit of phosphorus for the
warm shield; a live firefly or glowworm or the tail portions of four
dead ones for the chill shield.</p>
    <h6><a id="fire-storm">Fire Storm</a></h6>
    <p className="initial"><i>Evocation [Fire]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 8, Drd 7, Fire 7</span>
    <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 round</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Area</b>: Two 10-ft.
cubes per level
(S)</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span>
    <span className="stat-block"><b>Saving Throw</b>: Reflex half</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>When a fire storm spell is cast, the whole area is shot
through with sheets of roaring flame. The raging flames do not harm
natural vegetation, ground cover, and any plant creatures in the area
that you wish to exclude from damage. Any other creature within the
area takes 1d6 points of fire damage per caster level (maximum 20d6).</p>
    <h6><a id="fire-trap">Fire Trap</a></h6>
    <p className="initial"><i>Abjuration [Fire]</i></p>
    <span className="stat-block"><b>Level</b>: Drd 2, Sor/Wiz 4</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Object touched</span> <span className="stat-block"><b>Duration</b>:
Permanent until
discharged (D)</span> <span className="stat-block"><b>Saving Throw</b>:
Reflex half; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>Fire trap creates a fiery explosion when an intruder opens the
item that the trap protects. A fire trap can ward any object that can
be opened and closed.</p>
    <p>When casting fire trap, you select a point on the object as
the spell&rsquo;s center. When someone other than you opens the object,
a
fiery explosion fills the area within a 5-foot radius around the
spell&rsquo;s center. The flames deal 1d4 points of fire damage +1
point per
caster level (maximum +20). The item protected by the trap is not
harmed by this explosion.</p>
    <p>A fire trapped item cannot have a second closure or warding
spell placed on it.</p>
    <p>A knock spell does not bypass a fire trap. An unsuccessful
dispel magic spell does not detonate the spell.</p>
    <p>Underwater, this ward deals half damage and creates a large
cloud of steam.</p>
    <p>You can use the fire trapped object without discharging it, as
can any individual to whom the object was specifically attuned when
cast. Attuning a fire trapped object to an individual usually involves
setting a password that you can share with friends.</p>
    <p><i>Note</i>: Magic traps such as fire trap are hard to detect
and disable. A rogue (only) can use the <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a> skill to find a fire
trap and <a href="skillsAll.html#disable-device" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disable Device</a> to thwart it. The
DC in each case is 25 + spell
level (DC 27 for a druid&rsquo;s fire trap or DC 29 for the arcane
version).</p>
    <p><i>Material Component</i>: A half-pound of gold dust (cost 25
gp) sprinkled on the warded object.</p>
    <h6><a id="fireball">Fireball</a></h6>
    <p className="initial"><i>Evocation [Fire]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Area</b>: 20-ft.-radius
spread</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span>
    <span className="stat-block"><b>Saving Throw</b>: Reflex half</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>A fireball spell is an explosion of flame that detonates with
a low roar and deals 1d6 points of fire damage per caster level
(maximum 10d6) to every creature within the area. Unattended objects
also take this damage. The explosion creates almost no pressure.</p>
    <p>You point your finger and determine the range (distance and
height) at which the fireball is to burst. A glowing, pea-sized bead
streaks from the pointing digit and, unless it impacts upon a material
body or solid barrier prior to attaining the prescribed range, blossoms
into the fireball at that point. (An early impact results in an early
detonation.) If you attempt to send the bead through a narrow passage,
such as through an arrow slit, you must &ldquo;hit&rdquo; the opening
with a ranged
touch attack, or else the bead strikes the barrier and detonates
prematurely.</p>
    <p>The fireball sets fire to combustibles and damages objects in
the area. It can melt metals with low melting points, such as lead,
gold, copper, silver, and bronze. If the damage caused to an
interposing barrier shatters or breaks through it, the fireball may
continue beyond the barrier if the area permits; otherwise it stops at
the barrier just as any other spell effect does.</p>
    <p><i>Material Component</i>: A tiny ball of bat guano and sulfur.</p>
    <h6><a id="flame-arrow">Flame Arrow</a></h6>
    <p className="initial"><i>Transmutation [Fire]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: Fifty
projectiles, all of
which must be in contact with each other at the time of casting</span> <span className="stat-block"><b>Duration</b>: 10 min./level</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You turn ammunition (such as arrows, bolts, shuriken, and
stones) into fiery projectiles. Each piece of ammunition deals an extra
1d6 points of fire damage to any target it hits. A flaming projectile
can easily ignite a flammable object or structure, but it won&rsquo;t
ignite
a creature it strikes.</p>
    <p><i>Material Component</i>: A drop of oil and a small piece of
flint.</p>
    <h6><a id="flame-blade">Flame Blade</a></h6>
    <p className="initial"><i>Evocation [Fire]</i></p>
    <span className="stat-block"><b>Level</b>: Drd 2</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 0 ft.</span> <span className="stat-block"><b>Effect</b>:
Sword-like beam</span> <span className="stat-block"><b>Duration</b>: 1
min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>A 3-foot-long, blazing beam of red-hot fire springs forth from
your hand. You wield this bladelike beam as if it were a scimitar.
Attacks with the flame blade are melee touch attacks. The blade deals
1d8 points of fire damage +1 point per two caster levels (maximum +10).
Since the blade is immaterial, your Strength modifier does not apply to
the damage. A flame blade can ignite combustible materials such as
parchment, straw, dry sticks, and cloth.</p>
    <p>The spell does not function underwater.</p>
    <h6><a id="flame-strike">Flame Strike</a></h6>
    <p className="initial"><i>Evocation [Fire]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 5, Drd 4, Sun 5, War 5</span>
    <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Area</b>: Cylinder
(10-ft. radius, 40
ft. high)</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
Reflex half</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>A flame strike produces a vertical column of divine fire
roaring downward. The spell deals 1d6 points of damage per caster level
(maximum 15d6). Half the damage is fire damage, but the other half
results directly from divine power and is therefore not subject to
being reduced by resistance to fire-based attacks.</p>
    <h6><a id="flaming-sphere">Flaming Sphere</a></h6>
    <p className="initial"><i>Evocation [Fire]</i></p>
    <span className="stat-block"><b>Level</b>: Drd 2, Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Effect</b>:
5-ft.-diameter sphere</span> <span className="stat-block"><b>Duration</b>:
1 round/level</span> <span className="stat-block"><b>Saving Throw</b>:
Reflex negates</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>A burning globe of fire rolls in whichever direction you point
and burns those it strikes. It moves 30 feet per round. As part of this
movement, it can ascend or jump up to 30 feet to strike a target. If it
enters a space with a creature, it stops moving for the round and deals
2d6 points of fire damage to that creature, though a successful Reflex
save negates that damage. A flaming sphere rolls over barriers less
than 4 feet tall. It ignites flammable substances it touches and
illuminates the same area as a torch would.</p>
    <p>The sphere moves as long as you actively direct it (a move
action for you); otherwise, it merely stays at rest and burns. It can
be extinguished by any means that would put out a normal fire of its
size. The surface of the sphere has a spongy, yielding consistency and
so does not cause damage except by its flame. It cannot push aside
unwilling creatures or batter down large obstacles. A flaming sphere
winks out if it exceeds the spell&rsquo;s range.</p>
    <p><i>Arcane Material Component</i>: A bit of tallow, a pinch of
brimstone, and a dusting of powdered iron.</p>
    <h6><a id="flare">Flare</a></h6>
    <p className="initial"><i>Evocation [Light]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 0, Drd 0, Sor/Wiz 0</span>
    <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Effect</b>: Burst of light</span>
    <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: Fortitude negates</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This cantrip creates a burst of light. If you cause the light
to burst directly in front of a single creature, that creature is <a href="abilitiesAndConditions.html#dazzled" style={{
        "color": "rgb(87, 158, 182)"
      }}>dazzled</a> for 1 minute unless it
makes a successful Fortitude save.
Sightless creatures, as well as creatures already dazzled, are not
affected by flare.</p>
    <h6><a id="flesh-to-stone">Flesh to Stone</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 6</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Target</b>: One creature</span>
    <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: Fortitude negates</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>The subject, along with all its carried gear, turns into a
mindless, inert statue. If the statue resulting from this spell is
broken or damaged, the subject (if ever returned to its original state)
has similar damage or deformities. The creature is not <a href="abilitiesAndConditions.html#dead" style={{
        "color": "rgb(87, 158, 182)"
      }}>dead</a>, but it
does not seem to be alive either when viewed with spells such as
deathwatch.</p>
    <p>Only creatures made of flesh are affected by this spell.</p>
    <p><i>Material Component</i>: Lime, water, and earth.</p>
    <h6><a id="fly">Fly</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 3, Travel 3</span>
    <span className="stat-block"><b>Components</b>: V, S, F/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature touched</span> <span className="stat-block"><b>Duration</b>: 1
min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless)</span>
    <p>The subject can fly at a speed of 60 feet (or 40 feet if it
wears medium or heavy armor, or if it carries a medium or heavy load).
It can ascend at half speed and descend at double speed, and its
maneuverability is good. Using a fly spell requires only as much
concentration as walking, so the subject can attack or cast spells
normally. The subject of a fly spell can <a href="specialAttacks.html#charge" style={{
        "color": "rgb(87, 158, 182)"
      }}>charge</a>
but not run, and it
cannot carry aloft more weight than its maximum load, plus any armor it
wears.</p>
    <p>Should the spell duration expire while the subject is still
aloft, the magic fails slowly. The subject floats downward 60 feet per
round for 1d6 rounds. If it reaches the ground in that amount of time,
it lands safely. If not, it falls the rest of the distance, taking 1d6
points of damage per 10 feet of fall. Since dispelling a spell
effectively ends it, the subject also descends in this way if the fly
spell is dispelled, but not if it is negated by an antimagic field.</p>
    <p><i>Arcane Focus</i>: A wing feather from any bird.</p>
    <h6><a id="floating-disk">Floating Disk</a></h6>
    <p className="initial"><i>Evocation [Force]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 1</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Effect</b>: 3-ft.-diameter
disk of
force</span> <span className="stat-block"><b>Duration</b>: 1 hour/level</span>
    <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You create a slightly concave, circular plane of force that
follows you about and carries loads for you. The disk is 3 feet in
diameter and 1 inch deep at its center. It can hold 100 pounds of
weight per caster level. (If used to transport a liquid, its capacity
is 2 gallons.) The disk floats approximately 3 feet above the ground at
all times and remains level. It floats along horizontally within spell
range and will accompany you at a rate of no more than your normal
speed each round. If not otherwise directed, it maintains a constant
interval of 5 feet between itself and you. The disk winks out of
existence when the spell duration expires. The disk also winks out if
you move beyond range or try to take the disk more than 3 feet away
from the surface beneath it. When the disk winks out, whatever it was
supporting falls to the surface beneath it.</p>
    <p><i>Material Component</i>: A drop of mercury.</p>
    <h6><a id="fog-cloud">Fog Cloud</a></h6>
    <p className="initial"><i>Conjuration (Creation)</i></p>
    <span className="stat-block"><b>Level</b>: Drd 2, Sor/Wiz 2, Water 2</span>
    <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10 ft.
level)</span> <span className="stat-block"><b>Effect</b>: Fog spreads in
20-ft.
radius, 20 ft. high</span> <span className="stat-block"><b>Duration</b>:
10 min./level</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>A bank of fog billows out from the point you designate. The
fog obscures all sight, including darkvision, beyond 5 feet. A creature
within 5 feet has concealment (attacks have a 20% miss chance).
Creatures farther away have total concealment (50% miss chance, and the
attacker can&rsquo;t use sight to locate the target).</p>
    <p>A moderate wind (11+ mph) disperses the fog in 4 rounds; a
strong wind (21+ mph) disperses the fog in 1 round.</p>
    <p>The spell does not function underwater.</p>
    <h6><a id="forbiddance">Forbiddance</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Clr 6</span> <span className="stat-block"><b>Components</b>: V, S, M, DF</span> <span className="stat-block"><b>Casting Time</b>: 6 rounds</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Area</b>: 60-ft.
cube/level (S)</span> <span className="stat-block"><b>Duration</b>:
Permanent</span> <span className="stat-block"><b>Saving Throw</b>: See text</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>Forbiddance seals an area against all planar travel into or
within it. This includes all teleportation spells (such as dimension
door and teleport), plane shifting, astral travel, ethereal travel, and
all summoning spells. Such effects simply fail automatically.</p>
    <p>In addition, it damages entering creatures whose alignments
are different from yours. The effect on those attempting to enter the
warded area is based on their alignment relative to yours (see below).
A creature inside the area when the spell is cast takes no damage
unless it exits the area and attempts to reenter, at which time it is
affected as normal.</p>
    <p><i>Alignments identical</i>: No effect. The creature may enter
the area freely (although not by planar travel).</p>
    <p><i>Alignments different with respect to either law/chaos or
good/evil</i>: The creature takes 6d6 points of damage. A successful
Will save halves the damage, and spell resistance applies.</p>
    <p><i>Alignments different with respect to both law/chaos and
good/evil</i>: The creature takes 12d6 points of damage. A successful
Will save halves the damage, and spell resistance applies.</p>
    <p>At your option, the abjuration can include a password, in
which case creatures of alignments different from yours can avoid the
damage by speaking the password as they enter the area. You must select
this option (and the password) at the time of casting.</p>
    <p>Dispel magic does not dispel a forbiddance effect unless the
dispeller&rsquo;s level is at least as high as your caster level.</p>
    <p>You can&rsquo;t have multiple overlapping forbiddance effects.
In
such a case, the more recent effect stops at the boundary of the older
effect.</p>
    <p><i>Material Component</i>: A sprinkling of holy water and rare
incenses worth at least 1,500 gp, plus 1,500 gp per 60-foot cube. If a
password is desired, this requires the burning of additional rare
incenses worth at least 1,000 gp, plus 1,000 gp per 60-foot cube.</p>
    <h6><a id="forcecage">Forcecage</a></h6>
    <p className="initial"><i>Evocation [Force]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 7</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Area</b>: Barred cage
(20-ft. cube)
or windowless cell (10-ft. cube)</span> <span className="stat-block"><b>Duration</b>:
2 hours/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This powerful spell brings into being an immobile, <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a>
cubical prison composed of either bars of force or solid walls of force
(your choice).</p>
    <p>Creatures within the area are caught and contained unless they
are too big to fit inside, in which case the spell automatically fails.
Teleportation and other forms of astral travel provide a means of
escape, but the force walls or bars extend into the Ethereal Plane,
blocking ethereal travel.</p>
    <p>Like a wall of force spell, a forcecage resists dispel magic,
but it is vulnerable to a disintegrate spell, and it can be destroyed
by a sphere of annihilation or a rod of cancellation.</p>
    <p><i>Barred Cage</i>: This version of the spell produces a
20-foot cube made of bands of force (similar to a wall of force spell)
for bars. The bands are a half-inch wide, with half-inch gaps between
them. Any creature capable of passing through such a small space can
escape; others are confined. You can&rsquo;t attack a creature in a
barred
cage with a weapon unless the weapon can fit between the gaps. Even
against such weapons (including arrows and similar ranged attacks), a
creature in the barred cage has cover. All spells and breath weapons
can pass through the gaps in the bars.</p>
    <p><i>Windowless Cell</i>: This version of the spell produces a
10-foot cube with no way in and no way out. Solid walls of force form
its six sides.</p>
    <p><i>Material Component</i>: Ruby dust worth 1,500 gp, which is
tossed into the air and disappears when you cast the spell.</p>
    <h6><a id="forceful-hand">Forceful Hand</a></h6>
    <p className="initial"><i>Evocation [Force]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 6</span> <span className="stat-block"><b>Components</b>: V, S, F</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="spellsHtoL.html#interposing-hand">interposing hand</a>, except that the
forceful hand pursues and pushes away the opponent that you designate.
Treat this attack as a <a href="specialAttacks.html#bull-rush" style={{
        "color": "rgb(87, 158, 182)"
      }}>bull rush</a> with a +14 bonus on
the Strength check
(+8 for Strength 27, +4 for being Large, and a +2 bonus for charging,
which it always gets). The hand always moves with the opponent to push
that target back the full distance allowed, and it has no speed limit.
Directing the spell to a new target is a move action.</p>
    <p>A very strong creature could not push the hand out of its way
because the latter would instantly reposition itself between the
creature and you, but an opponent could push the hand up against you by
successfully bull rushing it.</p>
    <p><i>Focus</i>: A sturdy glove made of leather or heavy cloth.</p>
    <h6><a id="foresight">Foresight</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Drd 9, Knowledge 9,
Sor/Wiz 9</span> <span className="stat-block"><b>Components</b>: V, S, M/DF</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Personal or touch</span> <span className="stat-block"><b>Target</b>: See text</span> <span className="stat-block"><b>Duration</b>: 10 min./level</span> <span className="stat-block"><b>Saving Throw</b>: None or Will
negates (harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
No or Yes
(harmless)</span>
    <p>This spell grants you a powerful sixth sense in relation to
yourself or another. Once foresight is cast, you receive instantaneous
warnings of impending danger or harm to the subject of the spell. You
are never surprised or <a href="abilitiesAndConditions.html#flat-footed" style={{
        "color": "rgb(87, 158, 182)"
      }}>flat-footed</a>. In addition, the
spell gives you a
general idea of what action you might take to best protect yourself and
gives you a +2 insight bonus to AC and Reflex saves. This insight bonus
is lost whenever you would lose a Dexterity bonus to AC.</p>
    <p>When another creature is the subject of the spell, you receive
warnings about that creature. You must communicate what you learn to
the other creature for the warning to be useful, and the creature can
be caught unprepared in the absence of such a warning. Shouting a
warning, yanking a person back, and even telepathically communicating
(via an appropriate spell) can all be accomplished before some danger
befalls the subject, provided you act on the warning without delay. The
subject, however, does not gain the insight bonus to AC and Reflex
saves.</p>
    <p><i>Arcane Material Component</i>: A hummingbird&rsquo;s
feather.</p>
    <h6><a id="foxs-cunning">Fox&rsquo;s Cunning</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature touched</span> <span className="stat-block"><b>Duration</b>: 1
min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>The transmuted creature becomes smarter. The spell grants a +4
enhancement bonus to Intelligence, adding the usual benefits to
Intelligence-based skill checks and other uses of the Intelligence
modifier. Wizards (and other spellcasters who rely on Intelligence)
affected by this spell do not gain any additional bonus spells for the
increased Intelligence, but the save DCs for spells they cast while
under this spell&rsquo;s effect do increase. This spell doesn&rsquo;t
grant extra
skill points.</p>
    <p><i>Arcane Material Component</i>: A few hairs, or a pinch of
dung, from a fox.</p>
    <h6><a id="mass-foxs-cunning">Fox&rsquo;s Cunning, Mass</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Brd 6, Sor/Wiz 6</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One
creature/level, no
two of which can be more than 30 ft. apart</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#foxs-cunning">fox&rsquo;s cunning</a>, except that it
affects multiple creatures.</p>
    <h6><a id="freedom">Freedom</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 9</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels) or see text</span> <span className="stat-block"><b>Target</b>: One
creature</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span>
    <span className="stat-block"><b>Saving Throw</b>: Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>The subject is freed from spells and effects that restrict its
movement, including <a style={{
        "color": "#579eb6"
      }} href="spellsPtoR.html#blanar-binding">binding</a>, <a href="abilitiesAndConditions.html#entangled" style={{
        "color": "rgb(87, 158, 182)"
      }}>entangle</a>, <a href="abilitiesAndConditions.html#grappling" style={{
        "color": "rgb(87, 158, 182)"
      }}>grappling</a>, <a style={{
        "color": "#579eb6"
      }} href="spellsHtoL.html#imprisonment">imprisonment</a>, <a style={{
        "color": "#579eb6"
      }} href="spellsMtoO.html#maze">maze</a>, <a href="abilitiesAndConditions.html#paralyzed" style={{
        "color": "rgb(87, 158, 182)"
      }}>paralysis</a>, petrification, <a href="abilitiesAndConditions.html#pinned" style={{
        "color": "rgb(87, 158, 182)"
      }}>pinning</a>, <a style={{
        "color": "#579eb6"
      }} href="spellsS.html#sleep">sleep</a>, <a style={{
        "color": "#579eb6"
      }} href="spellsS.html#slow">slow</a>, <a href="abilitiesAndConditions.html#stunned" style={{
        "color": "rgb(87, 158, 182)"
      }}>stunning</a>, <a style={{
        "color": "#579eb6"
      }} href="spellsTtoZ.html#temporal-stasis">temporal
stasis</a>, and <a style={{
        "color": "#579eb6"
      }} href="spellsTotZ.html#web">web</a>. To free a creature from imprisonment or maze, you must
know its name and background, and you must cast this spell at the spot
where it was entombed or banished into the maze.</p>
    <h6><a id="freedom-of-movement">Freedom of Movement</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Brd 4, Clr 4, Drd 4, Luck
4, Rgr 4</span> <span className="stat-block"><b>Components</b>: V, S, M, DF</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Personal or touch</span> <span className="stat-block"><b>Target</b>: You or creature touched</span> <span className="stat-block"><b>Duration</b>: 10 min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless)</span>
    <p>This spell enables you or a creature you touch to move and
attack normally for the duration of the spell, even under the influence
of magic that usually impedes movement, such as paralysis, solid fog,
slow, and web. The subject automatically succeeds on any <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a>
check
made to resist a grapple attempt, as well as on grapple checks or <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#escape-artist">Escape Artist</a>
checks made to escape a grapple or a pin.</p>
    <p>The spell also allows the subject to move and attack normally
while underwater, even with slashing weapons such as axes and swords or
with bludgeoning weapons such as flails, hammers, and maces, provided
that the weapon is wielded in the hand rather than hurled. The freedom
of movement spell does not, however, allow water breathing.</p>
    <p><i>Material Component</i>: A leather thong, bound around the
arm or a similar appendage.</p>
    <h6><a id="freezing-sphere">Freezing Sphere</a></h6>
    <p className="initial"><i>Evocation [Cold]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 6</span> <span className="stat-block"><b>Components</b>: V, S, F</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span style={{
      "fontWeight": "bold"
    }}>Target, Effect, or
Area:</span> See text 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
or 1 round/level; see text 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Reflex
half; see text 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
    <p>Freezing sphere creates a frigid globe of cold energy that
streaks from your fingertips to the location you select, where it
explodes in a 10-foot-radius burst, dealing 1d6 points of cold damage
per caster level (maximum 15d6) to each creature in the area. An
elemental (water) creature instead takes 1d8 points of cold damage per
caster level (maximum 15d8).</p>
    <p>If the freezing sphere strikes a body of water or a liquid
that is principally water (not including water-based creatures), it
freezes the liquid to a depth of 6 inches over an area equal to 100
square feet (a 10- foot square) per caster level (maximum 1,500 square
feet). This ice lasts for 1 round per caster level. Creatures that were
swimming on the surface of frozen water become trapped in the ice.
Attempting to break free is a full-round action. A trapped creature
must make a DC 25 Strength check or a DC 25 <a href="skillsAll.html#scape-artist" style={{
        "color": "rgb(87, 158, 182)"
      }}>Escape
Artist</a> check to do
so.</p>
    <p>You can refrain from firing the globe after completing the
spell, if you wish. Treat this as a touch spell for which you are
holding the charge. You can hold the charge for as long as 1 round per
level, at the end of which time the freezing sphere bursts centered on
you (and you receive no saving throw to resist its effect). Firing the
globe in a later round is a standard action.</p>
    <p><i>Focus</i>: A small crystal sphere.</p>
    <h6><a id="gaseous-form">Gaseous Form</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Air 3, Brd 3, Sor/Wiz 3</span>
    <span className="stat-block"><b>Components</b>: S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Willing corporeal
creature touched</span> <span className="stat-block"><b>Duration</b>: 2
min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>The subject and all its gear become insubstantial, misty, and
translucent. Its material armor (including natural armor) becomes
worthless, though its size, Dexterity, deflection bonuses, and armor
bonuses from force effects still apply. The subject gains <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#damage-reduction">damage
reduction</a> 10/magic and becomes immune to poison and critical hits. It
can&rsquo;t attack or cast spells with verbal, somatic, material, or
focus
components while in gaseous form. (This does not rule out the use of
certain spells that the subject may have prepared using the feats <a href="featsAll.html#silent-spell" style={{
        "color": "rgb(87, 158, 182)"
      }}>Silent
Spell</a>, <a href="featsAll.html#still-spell" style={{
        "color": "rgb(87, 158, 182)"
      }}>Still
Spell</a>, and <a href="featsAll.html#eschew-materials" style={{
        "color": "rgb(87, 158, 182)"
      }}>Eschew Materials</a>.) The subject
also
loses supernatural abilities while in gaseous form. If it has a touch
spell ready to use, that spell is discharged harmlessly when the
gaseous form spell takes effect.</p>
    <p>A gaseous creature can&rsquo;t run, but it can fly at a speed
of 10
feet (maneuverability perfect). It can pass through small holes or
narrow openings, even mere cracks, with all it was wearing or holding
in its hands, as long as the spell persists. The creature is subject to
the effects of wind, and it can&rsquo;t enter water or other liquid. It
also
can&rsquo;t manipulate objects or activate items, even those carried
along
with its gaseous form. Continuously active items remain active, though
in some cases their effects may be moot.</p>
    <p><i>Arcane Material Component</i>: A bit of gauze and a wisp of
smoke.</p>
    <h6><a id="gate">Gate</a></h6>
    <p className="initial"><i>Conjuration (Creation or Calling)</i></p>
    <span className="stat-block"><b>Level</b>: Clr 9, Sor/Wiz 9</span> <span className="stat-block"><b>Components</b>: V, S, XP; see text</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Effect</b>: See text</span>
    <span className="stat-block"><b>Duration</b>: Instantaneous or
concentration (up to 1 round/level); see text</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>Casting a gate spell has two effects. First, it creates an
interdimensional connection between your plane of existence and a plane
you specify, allowing travel between those two planes in either
direction.</p>
    <p>Second, you may then call a particular individual or kind of
being through the gate.</p>
    <p>The gate itself is a circular hoop or disk from 5 to 20 feet
in diameter (caster&rsquo;s choice), oriented in the direction you
desire
when it comes into existence (typically vertical and facing you). It is
a two-dimensional window looking into the plane you specified when
casting the spell, and anyone or anything that moves through is shunted
instantly to the other side.</p>
    <p>A gate has a front and a back. Creatures moving through the
gate from the front are transported to the other plane; creatures
moving through it from the back are not.</p>
    <p><i>Planar Travel</i>: As a mode of planar travel, a gate spell
functions much like a plane shift spell, except that the gate opens
precisely at the point you desire (a creation effect). Deities and
other beings who rule a planar realm can prevent a gate from opening in
their presence or personal demesnes if they so desire. Travelers need
not join hands with you&mdash;anyone who chooses to step through the
portal
is transported. A gate cannot be opened to another point on the same
plane; the spell works only for interplanar travel.</p>
    <p>You may hold the gate open only for a brief time (no more than
1 round per caster level), and you must concentrate on doing so, or
else the interplanar connection is severed.</p>
    <p><i>Calling Creatures</i>: The second effect of the gate spell
is to call an extraplanar creature to your aid (a calling effect). By
naming a particular being or kind of being as you cast the spell, you
cause the gate to open in the immediate vicinity of the desired
creature and pull the subject through, willing or unwilling. Deities
and unique beings are under no compulsion to come through the gate,
although they may choose to do so of their own accord. This use of the
spell creates a gate that remains open just long enough to transport
the called creatures. This use of the spell has an XP cost (see below).</p>
    <p>If you choose to call a kind of creature instead of a known
individual you may call either a single creature (of any HD) or several
creatures. You can call and control several creatures as long as their
HD total does not exceed your caster level. In the case of a single
creature, you can control it if its HD do not exceed twice your caster
level. A single creature with more HD than twice your caster level
can&rsquo;t be controlled. Deities and unique beings cannot be
controlled in
any event. An uncontrolled being acts as it pleases, making the calling
of such creatures rather dangerous. An uncontrolled being may return to
its home plane at any time.</p>
    <p>A controlled creature can be commanded to perform a service
for you. Such services fall into two categories: immediate tasks and
contractual service. Fighting for you in a single battle or taking any
other actions that can be accomplished within 1 round per caster level
counts as an immediate task; you need not make any agreement or pay any
reward for the creature&rsquo;s help. The creature departs at the end
of the
spell.</p>
    <p>If you choose to exact a longer or more involved form of
service from a called creature, you must offer some fair trade in
return for that service. The service exacted must be reasonable with
respect to the promised favor or reward; see the lesser planar ally
spell for appropriate rewards. (Some creatures may want their payment
in &ldquo;livestock&rdquo; rather than in coin, which could involve
complications.)
Immediately upon completion of the service, the being is transported to
your vicinity, and you must then and there turn over the promised
reward. After this is done, the creature is instantly freed to return
to its own plane.</p>
    <p>Failure to fulfill the promise to the letter results in your
being subjected to service by the creature or by its liege and master,
at the very least. At worst, the creature or its kin may attack you.</p>
    <p><i>Note</i>: When you use a calling spell such as gate to call
an air, chaotic, earth, evil, fire, good, lawful, or water creature, it
becomes a spell of that type.</p>
    <p><i>XP Cost</i>: 1,000 XP (only for the calling creatures
function).</p>
    <h6><a id="geas-quest">Geas/Quest</a></h6>
    <p className="initial"><i>Enchantment (Compulsion)
[Language-Dependent, Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 6, Clr 6, Sor/Wiz 6</span>
    <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Target</b>: One living creature</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <p>This spell functions similarly to <a style={{
        "color": "#579eb6"
      }} href="#lesser-geas">lesser geas</a>, except that it
affects a creature of any HD and allows no saving throw.</p>
    <p>Instead of taking penalties to ability scores (as with lesser
geas), the subject takes 3d6 points of damage each day it does not
attempt to follow the geas/quest. Additionally, each day it must make a
Fortitude saving throw or become <a href="abilitiesAndConditions.html#sickened" style={{
        "color": "rgb(87, 158, 182)"
      }}>sickened</a>. These effects end 24
hours
after the creature attempts to resume the geas/ quest.</p>
    <p>A remove curse spell ends a geas/quest spell only if its
caster level is at least two higher than your caster level. Break
enchantment does not end a geas/quest, but limited wish, miracle, and
wish do.</p>
    <p>Bards, sorcerers, and wizards usually refer to this spell as
geas, while clerics call the same spell quest.</p>
    <h6><a id="lesser-geas">Geas, Lesser</a></h6>
    <p className="initial"><i>Enchantment (Compulsion)
[Language-Dependent, Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 3, Sor/Wiz 4</span> <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 1 round</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One living
creature with
7 HD or less</span> <span className="stat-block"><b>Duration</b>: One
day/level or until
discharged (D)</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>A lesser geas places a magical command on a creature to carry
out some service or to refrain from some action or course of activity,
as desired by you. The creature must have 7 or fewer Hit Dice and be
able to understand you. While a geas cannot compel a creature to kill
itself or perform acts that would result in certain death, it can cause
almost any other course of activity.</p>
    <p>The geased creature must follow the given instructions until
the geas is completed, no matter how long it takes.</p>
    <p>If the instructions involve some open-ended task that the
recipient cannot complete through his own actions the spell remains in
effect for a maximum of one day per caster level. A clever recipient
can subvert some instructions:</p>
    <p>If the subject is prevented from obeying the lesser geas for
24 hours, it takes a &ndash;2 penalty to each of its ability scores.
Each
day, another &ndash;2 penalty accumulates, up to a total of &ndash;8.
No ability
score can be reduced to less than 1 by this effect. The ability score
penalties are removed 24 hours after the subject resumes obeying the
lesser geas.</p>
    <p>A lesser geas (and all ability score penalties) can be ended
by break enchantment, limited wish, remove curse, miracle, or wish.
Dispel magic does not affect a lesser geas.</p>
    <h6><a id="gentle-repose">Gentle Repose</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Clr 2, Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Corpse touched</span> <span className="stat-block"><b>Duration</b>: One
day/level</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates
(object)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes
(object)</span>
    <p>You preserve the remains of a <a href="abilitiesAndConditions.html#dead" style={{
        "color": "rgb(87, 158, 182)"
      }}>dead</a> creature so that they do
not decay. Doing so effectively extends the time limit on raising that
creature from the dead (see raise dead). Days spent under the influence
of this spell don&rsquo;t count against the time limit. Additionally,
this
spell makes transporting a fallen comrade more pleasant.</p>
    <p>The spell also works on severed body parts and the like.</p>
    <p><i>Arcane Material Component</i>: A pinch of salt, and a
copper piece for each eye the corpse has (or had).</p>
    <h6><a id="ghost-sound">Ghost Sound</a></h6>
    <p className="initial"><i>Illusion (Figment)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 0, Sor/Wiz 0</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Effect</b>: Illusory sounds</span>
    <span className="stat-block"><b>Duration</b>: 1 round/level (D)</span>
    <span className="stat-block"><b>Saving Throw</b>: Will disbelief (if
interacted with)</span> <span className="stat-block"><b>Spell Resistance</b>:
No</span>
    <p>Ghost sound allows you to create a volume of sound that rises,
recedes, approaches, or remains at a fixed place. You choose what type
of sound ghost sound creates when casting it and cannot thereafter
change the sound&rsquo;s basic character.</p>
    <p>The volume of sound created depends on your level. You can
produce as much noise as four normal humans per caster level (maximum
twenty humans). Thus, talking, singing, shouting, walking, marching, or
running sounds can be created. The noise a ghost sound spell produces
can be virtually any type of sound within the volume limit. A horde of
rats running and squeaking is about the same volume as eight humans
running and shouting. A roaring lion is equal to the noise from sixteen
humans, while a roaring dire tiger is equal to the noise from twenty
humans.</p>
    <p>Ghost sound can enhance the effectiveness of a silent image
spell.</p>
    <p>Ghost sound can be made permanent with a permanency spell.</p>
    <p><i>Material Component</i>: A bit of wool or a small lump of
wax.</p>
    <h6><a id="ghoul-touch">Ghoul Touch</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Living humanoid touched</span> <span className="stat-block"><b>Duration</b>:
1d6+2 rounds</span> <span className="stat-block"><b>Saving Throw</b>:
Fortitude negates</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>Imbuing you with negative energy, this spell allows you to <a href="abilitiesAndConditions.html#paralyzed" style={{
        "color": "rgb(87, 158, 182)"
      }}>paralyze</a> a single living
humanoid for the duration of the spell with a
successful melee touch attack.</p>
    <p>Additionally, the paralyzed subject exudes a carrion stench
that causes all living creatures (except you) in a 10-foot-radius
spread to become <a href="abilitiesAndConditions.html#sickened" style={{
        "color": "rgb(87, 158, 182)"
      }}>sickened</a> (Fortitude negates). A
neutralize poison
spell removes the effect from a sickened creature, and creatures immune
to poison are unaffected by the stench.</p>
    <p><i>Material Component</i>: A small scrap of cloth taken from
clothing worn by a ghoul, or a pinch of earth from a ghoul&rsquo;s lair.</p>
    <h6><a id="giant-vermin">Giant Vermin</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 4, Drd 4</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: Up to three
vermin, no
two of which can be more than 30 ft. apart</span> <span className="stat-block"><b>Duration</b>: 1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You turn three normal-sized centipedes, two normal-sized
spiders, or a single normal-sized scorpion into larger forms. Only one
type of vermin can be transmuted (so a single casting cannot affect
both a centipede and a spider), and all must be grown to the same size.
The size to which the vermin can be grown depends on your level; see
the table below.</p>
    <p>Any giant vermin created by this spell do not attempt to harm
you, but your control of such creatures is limited to simple commands
(&ldquo;Attack,&rdquo; &ldquo;Defend,&rdquo; &ldquo;Stop,&rdquo; and so
forth). Orders to attack a certain
creature when it appears or guard against a particular occurrence are
too complex for the vermin to understand. Unless commanded to do
otherwise, the giant vermin attack whoever or whatever is near them.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "125px"
          }}>Caster Level</th>
          <th style={{
            "width": "125px"
          }}>Vermin Size</th>
        </tr>
        <tr className="odd-row">
          <td>9th or lower</td>
          <td>Medium</td>
        </tr>
        <tr>
          <td>10th&ndash;13th</td>
          <td>Large</td>
        </tr>
        <tr className="odd-row">
          <td>14th&ndash;17th</td>
          <td>Huge</td>
        </tr>
        <tr>
          <td>18th&ndash;19th</td>
          <td>Gargantuan</td>
        </tr>
        <tr className="odd-row">
          <td>20th or higher</td>
          <td>Colossal</td>
        </tr>
      </tbody>
    </table>
    <h6><a id="glibness">Glibness</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Brd 3</span> <span className="stat-block"><b>Components</b>: S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
10 min./level (D)</span>
    <p>Your speech becomes fluent and more believable. You gain a +30
bonus on <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a> checks made to
convince another of the truth of your
words. (This bonus doesn&rsquo;t apply to other uses of the Bluff
skill, such
as <a href="specialAttacks.html#feint" style={{
        "color": "rgb(87, 158, 182)"
      }}>feinting</a> in combat, creating a
diversion to hide, or communicating a
hidden message via innuendo.)</p>
    <p>If a magical effect is used against you that would detect
your lies or force you to speak the truth the user of the effect must
succeed on a caster level check (1d20 + caster level) against a DC
of 15 + your caster level to succeed. Failure means the effect does not
detect your lies or force you to speak only the truth.</p>
    <h6><a id="glitterdust">Glitterdust</a></h6>
    <p className="initial"><i>Conjuration (Creation)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Area</b>: Creatures and
objects
within 10-ft.-radius spread</span> <span className="stat-block"><b>Duration</b>:
1 round/level</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates
(blinding only)</span> <span className="stat-block"><b>Spell Resistance</b>:
No</span>
    <p>A cloud of golden particles covers everyone and everything in
the area, causing creatures to become <a href="abilitiesAndConditions.html#blinded" style={{
        "color": "rgb(87, 158, 182)"
      }}>blinded</a> and visibly outlining <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> things for the
duration of the spell. All within the area are
covered by the dust, which cannot be removed and continues to sparkle
until it fades.</p>
    <p>Any creature covered by the dust takes a &ndash;40 penalty on <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
checks.</p>
    <p><i>Material Component</i>: Ground mica.</p>
    <h6><a id="globe-of-invulnerability">Globe of Invulnerability</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 6</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#lesser-flobe-of-invulnerability">lesser globe of invulnerability</a>,
except that it also excludes 4th-level spells and spell-like effects.</p>
    <h6><a id="lesser-globe-of-invulnerability">Globe of
Invulnerability, Lesser</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 4</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 10 ft.</span> <span className="stat-block"><b>Area</b>: 10-ft.-radius spherical
emanation, centered on you</span> <span className="stat-block"><b>Duration</b>:
1 round/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>An immobile, faintly shimmering magical sphere surrounds you
and excludes all spell effects of 3rd level or lower. The area or
effect of any such spells does not include the area of the lesser globe
of invulnerability. Such spells fail to affect any target located
within the globe. Excluded effects include spell-like abilities and
spells or spell-like effects from items. However, any type of spell can
be cast through or out of the magical globe. Spells of 4th level and
higher are not affected by the globe, nor are spells already in effect
when the globe is cast. The globe can be brought down by a targeted
dispel magic spell, but not by an area dispel magic. You can leave and
return to the globe without penalty.</p>
    <p>Note that spell effects are not disrupted unless their effects
enter the globe, and even then they are merely suppressed, not
dispelled. </p>
    <p>If a given spell has more than one level depending on which
character class is casting it, use the level appropriate to the caster
to determine whether lesser globe of invulnerability stops it.</p>
    <p><i>Material Component</i>: A glass or crystal bead that
shatters at the expiration of the spell.</p>
    <h6><a id="glyph-of-warding">Glyph of Warding</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Clr 3</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: Touch</span>
    <b>Target or Area</b>: Object touched or up to 5 sq. ft./level
    <b>Duration</b>: Permanent until discharged (D)
    <b>Saving Throw</b>: See text
    <b>Spell Resistance</b>: No (object) and Yes; see text
    <p>This powerful inscription harms those who enter, pass, or open
the warded area or object. A glyph of warding can guard a bridge or
passage, ward a portal, trap a chest or box, and so on.</p>
    <p>You set the conditions of the ward. Typically, any creature
entering the warded area or opening the warded object without speaking
a password (which you set when casting the spell) is subject to the
magic it stores. Alternatively or in addition to a password trigger,
glyphs can be set according to physical characteristics (such as height
or weight) or creature type, subtype, or kind. Glyphs can also be set
with respect to good, evil, law, or chaos, or to pass those of your
religion. They cannot be set according to class, Hit Dice, or level.
Glyphs respond to <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> creatures normally but
are not triggered by
those who travel past them ethereally. Multiple glyphs cannot be cast
on the same area. However, if a cabinet has three drawers, each can be
separately warded.</p>
    <p>When casting the spell, you weave a tracery of faintly glowing
lines around the warding sigil. A glyph can be placed to conform to any
shape up to the limitations of your total square footage. When the
spell is completed, the glyph and tracery become nearly invisible.</p>
    <p>Glyphs cannot be affected or bypassed by such means as
physical or magical probing, though they can be dispelled. Mislead,
polymorph, and nondetection (and similar magical effects) can fool a
glyph, though nonmagical disguises and the like can&rsquo;t. Read magic
allows you to identify a glyph of warding with a DC 13 <a href="skillsAll.html#spellcraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spellcraft</a>
check. Identifying the glyph does not discharge it and allows you to
know the basic nature of the glyph (version, type of damage caused,
what spell is stored).</p>
    <p><i>Note</i>: Magic traps such as glyph of warding are hard to
detect and disable. A rogue (only) can use the <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a>
skill to find the
glyph and <a href="skillsAll.html#disable-device" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disable Device</a> to thwart it. The
DC in each case is 25 +
spell level, or 28 for glyph of warding.</p>
    <p>Depending on the version selected, a glyph either blasts the
intruder or activates a spell.</p>
    <p><i>Blast Glyph</i>: A blast glyph deals 1d8 points of damage
per two caster levels (maximum 5d8) to the intruder and to all within 5
feet of him or her. This damage is acid, cold, fire, electricity, or
sonic (caster&rsquo;s choice, made at time of casting). Each creature
affected can attempt a Reflex save to take half damage. Spell
resistance applies against this effect.</p>
    <p><i>Spell Glyph</i>: You can store any harmful spell of 3rd
level or lower that you know. All level-dependent features of the spell
are based on your caster level at the time of casting the glyph. If the
spell has a target, it targets the intruder. If the spell has an area
or an amorphous effect the area or effect is centered on the intruder.
If the spell summons creatures, they appear as close as possible to the
intruder and attack. Saving throws and spell resistance operate as
normal, except that the DC is based on the level of the spell stored in
the glyph.</p>
    <p><i>Material Component</i>: You trace the glyph with incense,
which must first be sprinkled with powdered diamond worth at least 200
gp.</p>
    <h6><a id="greater-glyph-of-warding">Glyph of Warding, Greater</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Clr 6</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#glyph-of-warding">glyph of warding</a>, except that a
greater blast glyph deals up to 10d8 points of damage, and a greater
spell glyph can store a spell of 6th level or lower.</p>
    <p><i>Material Component</i>: You trace the glyph with incense,
which must first be sprinkled with powdered diamond worth at least 400
gp.</p>
    <h6><a id="goodberry">Goodberry</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 1</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Targets</b>:
2d4 fresh berries touched</span> <span className="stat-block"><b>Duration</b>:
One day/level</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>Casting goodberry upon a handful of freshly picked berries
makes 2d4 of them magical. You (as well as any other druid of 3rd or
higher level) can immediately discern which berries are affected. Each
transmuted berry provides nourishment as if it were a normal meal for a
Medium creature. The berry also cures 1 point of damage when eaten,
subject to a maximum of 8 points of such curing in any 24-hour period.</p>
    <h6><a id="good-hope">Good Hope</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 3</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Targets</b>: One living
creature/level, no two of which may be more than 30 ft. apart</span> <span className="stat-block"><b>Duration</b>: 1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless)</span>
    <p>This spell instills powerful hope in the subjects. Each
affected creature gains a +2 morale bonus on saving throws, attack
rolls, ability checks, skill checks, and weapon damage rolls.</p>
    <p>Good hope counters and dispels crushing despair.</p>
    <h6><a id="grasping-hand">Grasping Hand</a></h6>
    <p className="initial"><i>Evocation [Force]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 7, Strength 7</span>
    <span className="stat-block"><b>Components</b>: V, S, F/DF</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="spellsHtoL.html#interposing-hand">interposing hand</a>, except the hand
can also <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a> one opponent that you
select. The grasping hand gets
one grapple attack per round.</p>
    <p>Its attack bonus to make contact equals your caster level +
your Intelligence, Wisdom, or Charisma modifier (for wizards, clerics,
and sorcerers, respectively), +10 for the hand&rsquo;s Strength score
(31),
&ndash;1 for being Large. Its grapple bonus is this same figure, except
with
a +4 modifier for being Large instead of &ndash;1. The hand holds but
does
not harm creatures it grapples.</p>
    <p>Directing the spell to a new target is a move action.</p>
    <p>The grasping hand can also <a href="specialAttacks.html#bull-rush" style={{
        "color": "rgb(87, 158, 182)"
      }}>bull
rush</a> an opponent as forceful
hand does, but at a +16 bonus on the Strength check (+10 for Strength
35, +4 for being Large, and a +2 bonus for charging, which it always
gets), or interpose itself as interposing hand does.</p>
    <p>Clerics who cast this spell name it for their deities.</p>
    <p><i>Arcane Focus</i>: A leather glove.</p>
    <h6><a id="grease">Grease</a></h6>
    <p className="initial"><i>Conjuration (Creation)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1, Sor/Wiz 1</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span style={{
      "fontWeight": "bold"
    }}>Target or Area:</span>
One object or a 10-ft. square 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round/level
(D) 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> See text 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No
    <p>A grease spell covers a solid surface with a layer of slippery
grease. Any creature in the area when the spell is cast must make a
successful Reflex save or fall. This save is repeated on your turn each
round that the creature remains within the area. A creature can walk
within or through the area of grease at half normal speed with a DC 10 <a href="skillsAll.html#balance" style={{
        "color": "rgb(87, 158, 182)"
      }}>Balance</a>
check. Failure means it
can&rsquo;t move that round (and must then make a Reflex save or fall),
while
failure by 5 or more means it falls (see the Balance skill for details).</p>
    <p>The spell can also be used to create a greasy coating on an
item. Material objects not in use are always affected by this spell,
while an object wielded or employed by a creature receives a Reflex
saving throw to avoid the effect. If the initial saving throw fails,
the creature immediately drops the item. A saving throw must be made in
each round that the creature attempts to pick up or use the greased
item. A creature wearing greased armor or clothing gains a +10
circumstance bonus on <a href="skillsAll.html#escape-artist" style={{
        "color": "rgb(87, 158, 182)"
      }}>Escape Artist</a> checks and on
grapple checks made
to resist or escape a grapple or to escape a pin.</p>
    <p><i>Material Component</i>: A bit of pork rind or butter.</p>
    <h6><a id="greater-spell-name">Greater (Spell Name)</a></h6>
    <p className="initial"><i>Any spell whose name begins with greater is
alphabetized in this chapter according to the second word of the spell
name. Thus, the description of a greater spell appears near the
description of the spell on which it is based. Spell chains that have
greater spells in them include those based on the spells arcane sight,
command, dispel magic, glyph of warding, invisibility, magic fang,
magic weapon, planar ally, planar binding, prying eyes, restoration,
scrying, shadow conjuration, shadow evocation, shout, and teleport.</i></p>
    <h6><a id="guards-and-wards">Guards and Wards</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 6</span> <span className="stat-block"><b>Components</b>: V, S, M, F</span> <span className="stat-block"><b>Casting Time</b>: 30 minutes</span> <span className="stat-block"><b>Range</b>: Anywhere within the area
to be warded</span> <span className="stat-block"><b>Area</b>: Up to 200
sq. ft./level (S)</span> <span className="stat-block"><b>Duration</b>: 2
hours/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
See text</span> <span className="stat-block"><b>Spell Resistance</b>: See
text</span>
    <p>This powerful spell is primarily used to defend your
stronghold. The ward protects 200 square feet per caster level. The
warded area can be as much as 20 feet high, and shaped as you desire.
You can ward several stories of a stronghold by dividing the area among
them; you must be somewhere within the area to be warded to cast the
spell. The spell creates the following magical effects within the
warded area.</p>
    <p><i>Fog</i>: Fog fills all corridors, obscuring all sight,
including darkvision, beyond 5 feet. A creature within 5 feet has
concealment (attacks have a 20% miss chance). Creatures farther away
have total concealment (50% miss chance, and the attacker cannot use
sight to locate the target). Saving Throw: None. Spell Resistance: No.</p>
    <p><i>Arcane Locks</i>: All doors in the warded area are arcane
locked. Saving Throw: None. Spell Resistance: No.</p>
    <p><i>Webs</i>: Webs fill all stairs from top to bottom. These
strands are identical with those created by the web spell, except that
they regrow in 10 minutes if they are burned or torn away while the
guards and wards spell lasts. Saving Throw: Reflex negates; see text
for web. Spell Resistance: No.</p>
    <p><i>Confusion</i>: Where there are choices in
direction&mdash;such as
a corridor intersection or side passage&mdash;a minor confusion-type
effect
functions so as to make it 50% probable that intruders believe they are
going in the opposite direction from the one they actually chose. This
is an enchantment, mind-affecting effect. Saving Throw: None. Spell
Resistance: Yes.</p>
    <p><i>Lost Doors</i>: One door per caster level is covered by a
silent image to appear as if it were a plain wall. Saving Throw: Will
disbelief (if interacted with). Spell Resistance: No.</p>
    <p>In addition, you can place your choice of one of the following
five magical effects.</p>
    <ol>
      <li>
        <p>Dancing lights in four corridors. You can designate a
simple program that causes the lights to repeat as long as the guards
and wards spell lasts. Saving Throw: None. Spell Resistance: No.</p>
      </li>
      <li>
        <p>A magic mouth in two places. Saving Throw: None. Spell
Resistance: No.</p>
      </li>
      <li>
        <p>A stinking cloud in two places. The vapors appear in the
places you designate; they return within 10 minutes if dispersed by
wind while the guards and wards spell lasts. Saving Throw: Fortitude
negates; see text for stinking cloud. Spell Resistance: No.</p>
      </li>
      <li>
        <p>A gust of wind in one corridor or room. Saving Throw:
Fortitude negates. Spell Resistance: Yes.</p>
      </li>
      <li>
        <p>A suggestion in one place. You select an area of up to 5
feet square, and any creature who enters or passes through the area
receives the suggestion mentally. Saving Throw: Will negates. Spell
Resistance: Yes.</p>
      </li>
    </ol>
    <p>The whole warded area radiates strong magic of the abjuration
school. A dispel magic cast on a specific effect, if successful,
removes only that effect. A successful Mage&rsquo;s disjunction
destroys the
entire guards and wards effect.</p>
    <p><i>Material Component</i>: Burning incense, a small measure of
brimstone and oil, a knotted string, and a small amount of blood.</p>
    <p><i>Focus</i>: A small silver rod.</p>
    <h6><a id="guidance">Guidance</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Clr 0, Drd 0</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature touched</span> <span className="stat-block"><b>Duration</b>: 1
minute or until
discharged</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This spell imbues the subject with a touch of divine guidance.
The creature gets a +1 competence bonus on a single attack roll, saving
throw, or skill check. It must choose to use the bonus before making
the roll to which it applies.</p>
    <h6><a id="gust-of-wind">Gust of Wind</a></h6>
    <p className="initial"><i>Evocation [Air]</i></p>
    <span className="stat-block"><b>Level</b>: Drd 2, Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 60 ft.</span> <span className="stat-block"><b>Effect</b>: Line-shaped gust of
severe wind emanating out from you to the extreme of the range</span> <span className="stat-block"><b>Duration</b>: 1 round</span> <span className="stat-block"><b>Saving Throw</b>: Fortitude negates</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This spell creates a severe blast of air (approximately 50
mph) that originates from you, affecting all creatures in its path.</p>
    <p>A Tiny or smaller creature on the ground is <a href="abilitiesAndConditions.html#knocked-down" style={{
        "color": "rgb(87, 158, 182)"
      }}>knocked down</a> and
rolled 1d4x10 feet, taking 1d4 points of nonlethal damage per 10 feet.
If flying, a Tiny or smaller creature is blown back 2d6x10 feet and
takes 2d6 points of nonlethal damage due to battering and buffeting.</p>
    <p>Small creatures are knocked <a href="abilitiesAndConditions.html#prone" style={{
        "color": "rgb(87, 158, 182)"
      }}>prone</a> by the force of the wind,
or
if flying are blown back 1d6x10 feet.</p>
    <p>Medium creatures are unable to move forward against the force
of the wind, or if flying are blown back 1d6x5 feet.</p>
    <p>Large or larger creatures may move normally within a gust of
wind effect.</p>
    <p>A gust of wind can&rsquo;t move a creature beyond the limit of
its
range.</p>
    <p>Any creature, regardless of size, takes a &ndash;4 penalty on
ranged
attacks and <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a> checks in the area of a
gust of wind.</p>
    <p>The force of the gust automatically extinguishes candles,
torches, and similar unprotected flames. It causes protected flames,
such as those of lanterns, to dance wildly and has a 50% chance to
extinguish those lights.</p>
    <p>In addition to the effects noted, a gust of wind can do
anything that a sudden blast of wind would be expected to do. It can
create a stinging spray of sand or dust, fan a large fire, overturn
delicate awnings or hangings, heel over a small boat, and blow gases or
vapors to the edge of its range.</p>
    <p>Gust of wind can be made permanent with a permanency spell.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      